<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">提现</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :md="4" :lg="5" :xl="6">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :md="16" :lg="12" :xl="10">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
                                <!--提现方式-->
                                <el-form-item label="提现方式：" prop="type">
                                    <div class="pay-radio-box">
                                        <el-radio-group v-model="ruleForm.type" style="margin-right: 20px">
                                            <el-radio :label="myapply.value" v-for="(myapply, index) in applytype" :key="index" @change="clickRadioEvent">
                                                {{ myapply.name }}
                                            </el-radio>
                                        </el-radio-group>
                                        <el-button type="text" style="color: #e6a23c" @click="routeraddrClick('/chfn')">变更提现方式</el-button>
                                    </div>
                                </el-form-item>
                                <!-- 押金 -->
                                <el-form-item label="未结算金额：" v-if="this.hock_money > 0">
                                    <span style="font-size: 12px; color: #333333;">{{ this.hock_money / 100 }} 元</span>
                                </el-form-item>
                                <!--可提现金额-->
                                <el-form-item label="可提现金额：">
                                    <span style="font-size: 12px; color: red">{{ ruleForm.allmoney / 100 }} 元</span>
                                </el-form-item>
                                <!--提现金额-->
                                <el-form-item label="提现金额：" prop="money">
                                    <div style="display: flex; flex-direction: row">
                                        <el-input
                                            v-model="ruleForm.money"
                                            style="margin-right: 20px"
                                            placeholder="请输入提现金额"
                                            clearable
                                            step="0.1"
                                            min="0"
                                            :max="ruleForm.allmoney / 100"
                                        ></el-input>
                                        <el-button type="text" @click="allmoneyEvent((ruleForm.money = ruleForm.allmoney / 100))">全部提现</el-button>
                                    </div>
                                </el-form-item>
                                <!--费率-->
                                <el-form-item label="费率：">
                                    <span style="font-size: 12px; color: #333333">{{ ruleForm.rate }}%</span>
                                </el-form-item>
                                <!--上传发票-->
                                <el-form-item label="上传发票：">
                                    <mainimgesup
                                        ref="imagesupload"
                                        v-model="ruleForm.files"
                                        :myimgesup="myimgesup"
                                        :myimgesupisshow="myimgesupisshow"
                                        :filelist="filelist"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                                        v-on:delfiledata="(data) => delFileDataEvent(data)"
                                    ></mainimgesup>
                                </el-form-item>
                                <!--button按钮-->
                                <el-form-item style="margin-top: 50px">
                                    <el-button :disabled="mydisabled" type="primary" @click="submitForm('ruleForm')" style="width: 40%">提交</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :md="4" :lg="7" :xl="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import mainimgesup from '@/components/Mainimgesup.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'vagentlistedit',
    data() {
        // 提现金额-校验
        var checkMoney = (rule, value, callback) => {
            //获取key值为ide下的相关数据
            var getdatas = this.ruleForm
            if (!value) {
                return callback(new Error('提现金额不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 判断字符串是否为文字
                if (isNaN(value)) {
                    callback(new Error('请输入数字'))
                } else {
                    if (value < 0) {
                        callback(new Error('值需大于0'))
                    } else {
                        // 判断是否超过最大值
                        if (getdatas.money > getdatas.allmoney / 100) {
                            callback(new Error('目前最大可提现金额为：' + getdatas.allmoney / 100 + ' 元'))
                        } else {
                            callback()
                        }
                    }
                }
            }, 500)
        }
        return {
            mainonebread: [
                {
                    name: '财务'
                },
                {
                    name: '账户总览',
                    path: '/account'
                },
                {
                    name: '手动提现',
                    path: '/handwithdrawal'
                }
            ], // 当前页-【面包屑导航】

            myimgesupisshow: true, //form表单-imgesuploade上传图片
            myimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1表示ioss直传；2-表示PHP接口传输
                listtype: 'text', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/shop/Withdraw/upload', //upload上传API地址
                headers: { Authen: localStorage.getItem('token') }, //upload上传headers
                multiple: false, //上传多个文件
                title: '上传发票', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '(1)图片支持格式：*.jpg,*.png; (2)文件支持格式：*.pdf; (3)最大支持上传10M文件', //提示&建议文字
                limit: '', //上传数量限制
                target_id: 11, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；11提现发票
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/png' //文件格式提示规则
                    },
                    // video: {
                    //   size: 10000, //文件大小（单位kb）
                    //   type: ["video/mp4"], //文件格式
                    //   typechart: "mp4", //文件格式提示规则
                    // },
                    files: {
                        size: 10000, //文件大小（单位kb）
                        type: ['application/pdf', 'application/msword'], //文件格式
                        typechart: 'pdf/msword' //文件格式提示规则
                    }
                }
            },
            filelist: [
                // {
                //   id: "12312",
                //   name: "food.jpeg",
                //   url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
                // },
            ],

            //form表单数据
            ruleForm: {
                type: '',
                allmoney: '',
                money: '',
                rate: '',
                files: []
            },
            //form表单填写规则-前端判断是否必填
            rules: {
                type: [
                    {
                        required: true,
                        message: '提现方式为必填项',
                        trigger: 'blur'
                    }
                ],
                money: [
                    {
                        required: true,
                        validator: checkMoney,
                        trigger: 'blur'
                    }
                ],
                files: [
                    {
                        required: true,
                        message: '请上传广告图片/视频',
                        trigger: 'change'
                    }
                ]
            },

            // 默认提现方式 default_pay： 1、微信；2、支付宝
            paytype: '',
            // 提现方式选项
            applytype: [
                {
                    name: '微信',
                    value: '1',
                    rate: ''
                }
            ],

            // 前端防抖字段-点击接口时
            mydisabled: false,

            mytoken: localStorage.getItem('token'), //获取存储的token

            // 可提现总金额
            applyset_apply_money: '',

            loading: false
        }
    },
    mounted() {},
    methods: {
        //获取upload子组件回传的数据-fiels数据-更新（删除）
        delFileDataEvent(data) {
            console.log('当前获取删除的file数据：', data)
            let getcontentdata = this.ruleForm.files.filter((n) => n != data.url)
            this.ruleForm.files = getcontentdata
        },
        //获取upload子组件回传的数据-file文件url等数据
        getUploadFileEvent(data) {
            console.log('当前获取的回传数据：', data)
            this.ruleForm.files.push(data.res.requestUrls[0])
        },
        //提交form表单-并校验
        submitForm(formName) {
            // 前端防抖字段-点击接口时; true禁用，false启用
            this.mydisabled = true
            let getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log('当前提交成功-获取的form表单值：', this.$refs[formName].model)
                    let files = getdatas.files.join(',')
                    console.log(files)
                    axios
                        .post(
                            '/shop/withdraw/apply',
                            {
                                payway: getdatas.type,
                                amount: getdatas.money,
                                fapiao: files
                            },
                            {
                                headers: {
                                    Authen: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                // 前端防抖字段-点击接口时; true禁用，false启用
                                this.mydisabled = false
                                ElMessageBox.alert(response.data.message, '提示:', {
                                    confirmButtonText: 'OK',
                                    callback: () => {
                                        // 成功之后返回上一级
                                        this.$router.go(-1) //返回上一层
                                    }
                                })
                            } else {
                                // 前端防抖字段-点击接口时; true禁用，false启用
                                this.mydisabled = false
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            // 前端防抖字段-点击接口时; true禁用，false启用
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    // 前端防抖字段-点击接口时; true禁用，false启用
                    this.mydisabled = false
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr) {
            //仅跳转
            this.$router.push(routeraddr).catch((error) => error)
        },

        // 切换提现方式
        clickRadioEvent(data) {
            let getcontentdata = this.applytype.filter((n) => n.value == data)[0]
            this.ruleForm = {
                type: data,
                allmoney: this.applyset_apply_money,
                money: '',
                rate: getcontentdata.rate,
                files: []
            }
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            // 加载-提现信息
            axios
                .post(
                    '/shop/Withdraw/applySet',
                    {},
                    {
                        headers: {
                            Authen: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    var data = response.data.result
                    this.applyset_apply_money = response.data.result.apply_money
                    // 押金
                    this.hock_money = data.hock_money

                    // 加载提现方式选项
                    var newpayway = []
                    Object.keys(data.payway).forEach((element) => {
                        newpayway.push(data.payway[element])
                    })
                    this.applytype = newpayway

                    // 默认提现方式：为第一个
                    this.clickRadioEvent(this.applytype[0].value)

                    if (newpayway.length > 0) {
                        // 获取账户信息-默认提现方式
                        axios
                            .post(
                                '/shop/ShopSet/accountInfo',
                                {},
                                {
                                    headers: {
                                        Authen: localStorage.getItem('token')
                                    }
                                }
                            )
                            .then((response) => {
                                var routedatas = response.data.result
                                this.paytype = routedatas.default_pay

                                // // paytype-默认提现方式
                                // this.paytype = routedatas.default_pay;
                                // if (this.paytype == 1 ) {
                                //   let getcontentdata = this.applytype.filter(
                                //     (n) => n.value != "2"
                                //   );
                                //   this.applytype = getcontentdata;
                                //   // 加载-提现相关信息
                                //   this.ruleForm = {
                                //     type: "1",
                                //     allmoney: data.apply_money,
                                //     money: "",
                                //     rate: this.applytype[0].rate,
                                //     files: [],
                                //   };
                                // }
                                // if (this.paytype == 2) {
                                //   let getcontentdata = this.applytype.filter(
                                //     (n) => n.value != "1"
                                //   );
                                //   this.applytype = getcontentdata;
                                //   // 加载-提现相关信息
                                //   this.ruleForm = {
                                //     type: "2",
                                //     allmoney: data.apply_money,
                                //     money: "",
                                //     rate: this.applytype[0].rate,
                                //     files: [],
                                //   };
                                // }
                            })
                            .catch(function(error) {
                                console.log(error)
                            })
                    } else {
                        ElMessage({
                            type: 'warning',
                            message: '请联系管理员，数据有问题！'
                        })
                    }

                    this.loading = false
                })
                .catch(function(error) {
                    console.log(error)

                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        mainimgesup
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #97a0c3;
    padding: 5px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #17418f;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
}

.pay-radio-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
</style>
